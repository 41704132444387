@import 'antd/dist/antd.variable.min.css';

.dashboard-layout {
  min-height: 100vh;
}

.auth-layout {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

img.responsive {
  max-width: 100%;
}

.logo-topbar {
  background: url('../images/topbar-logo.svg') no-repeat;
  float: left;
  width: 120px;
  height: 31px;
  margin: 1rem 1.5rem 1rem 0;
}

.site-layout-content {
  margin-top: 2rem;
  min-height: 280px;
  padding: 1rem;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
}

.project-dashboard-row {
  display: flex;
  flex-direction: row;
  gap: 1.75rem;
  flex-wrap: nowrap;
  flex-grow: 0;
  flex-shrink: 1;
}

.project-dashboard-column {
  height: calc(100vh - 23rem);
  overflow-y: auto;
  background-color: #f0f2f5;
  padding: 1rem;
  border-radius: 4px;
  flex-shrink: 1;
  border: 1px solid #eee;
}

.ant-modal-content {
  border-radius: 4px;
}

.ant-modal-header {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.ant-btn,
.ant-input,
.ant-select,
.ant-select-selector,
.ant-input-password,
.ant-input-affix-wrapper,
.ant-card-bordered,
.ant-input-number-input,
.ant-input-number,
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 4px;
}

.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab {
  border-radius: 4px 4px 0 0;
}

button.link {
  appearance: none;
  background: none;
  border: none;
  display: inline-block;
  margin: 0;
  padding: 0;
}

button.link:hover {
  text-decoration: underline;
  cursor: pointer;
}

.ant-comment-content-author-name {
  color: #5b5b5b;
}

.ant-comment-content-author-time {
  color: #a3a3a3;
}
